import { createContext } from 'react';

export const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => {},
});
export const LocaleContext = createContext<unknown>({
  locale: null,
  setLocale: () => {},
});
export const ZoomContext = createContext<unknown>(null);
export const NotificationsContext = createContext<unknown>(null);
export const LoaderContext = createContext<Function>(() => {});
export const SnackbarContext = createContext<SnackbarPropsType>({
  snackbarProps: { open: false, message: '', variant: '', onClose: () => {} },
  setSnackbarProps: () => {},
});
